import { useState, useEffect } from 'react';
import { S3Client, ListObjectsV2Command } from '@aws-sdk/client-s3';
import { DynamoDB } from 'aws-sdk';
import { APP_CONFIG } from '../config/constants';
import { useAuth } from '../contexts/AuthContext';

interface DeviceListHook {
  deviceList: string[];
  loading: boolean;
  error: Error | null;
  displayTabs: string[];
}

export const useDeviceList = (): DeviceListHook => {
  const [deviceList, setDeviceList] = useState<string[]>([]);
  const [displayTabs, setDisplayTabs] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const { user } = useAuth();

  useEffect(() => {
    const fetchDeviceList = async () => {
      try {
        const dynamoDB = new DynamoDB.DocumentClient({
          region: APP_CONFIG.AWS_CONFIG.REGION,
          credentials: {
            accessKeyId: APP_CONFIG.AWS_CONFIG.ACCESS_KEY_ID!,
            secretAccessKey: APP_CONFIG.AWS_CONFIG.SECRET_ACCESS_KEY!,
          },
        });

        const scanParams = {
          TableName: 'mizlinxAppUsers',
          FilterExpression: '#email = :email',
          ExpressionAttributeNames: {
            '#email': 'email'
          },
          ExpressionAttributeValues: {
            ':email': user?.attributes?.email
          }
        };

        const userResult = await dynamoDB.scan(scanParams).promise();
        const userItem = userResult.Items?.[0];
        
        // 許可されたデバイスIDとタブ情報を取得
        const permittedDevices = userItem?.permittedDeviceId?.values || [];
        const userDisplayTabs = userItem?.displayTab?.values || ['device', 'heatmap', 'sensor', 'video'];
        setDisplayTabs(userDisplayTabs);

        const s3Client = new S3Client({
          region: APP_CONFIG.AWS_CONFIG.REGION,
          credentials: {
            accessKeyId: APP_CONFIG.AWS_CONFIG.ACCESS_KEY_ID!,
            secretAccessKey: APP_CONFIG.AWS_CONFIG.SECRET_ACCESS_KEY!,
          },
        });

        const listCommand = new ListObjectsV2Command({
          Bucket: APP_CONFIG.S3_CONFIG.BUCKET_NAME,
          Prefix: `${APP_CONFIG.S3_CONFIG.BASE_PATH}/`,
          Delimiter: '/'
        });

        const result = await s3Client.send(listCommand);
        
        const devices = result.CommonPrefixes
          ?.map((prefix: { Prefix?: string }) => {
            const match = prefix.Prefix?.match(/data-lake\/(MM[^/]+)\//);
            return match ? match[1] : null;
          })
          .filter((device: string | null): device is string => 
            device !== null && permittedDevices.includes(device))
          .sort() || [];

        setDeviceList(devices);
      } catch (err) {
        console.error('デバイスリストの取得に失敗:', err);
        setError(err as Error);
      } finally {
        setLoading(false);
      }
    };

    if (user) {
      fetchDeviceList();
    }
  }, [user]);

  return { deviceList, loading, error, displayTabs };
};
