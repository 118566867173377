import { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import {
  signIn as amplifySignIn,
  signOut as amplifySignOut,
  getCurrentUser,
  fetchAuthSession,
} from '@aws-amplify/auth';
import Cookies from 'js-cookie';

// カスタムのAuthUser型を定義
interface CustomAuthUser {
  username: string;
  attributes?: {
    email?: string;
    [key: string]: any;
  };
}

interface AuthContextType {
  isAuthenticated: boolean;
  user: CustomAuthUser | null;
  signIn: (username: string, password: string) => Promise<void>;
  signOut: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

const getCookieOptions = () => ({
  expires: 7,
  secure: window.location.protocol === 'https:',
  sameSite: 'strict' as const,
  path: '/',
});

export function AuthProvider({ children }: { children: ReactNode }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<CustomAuthUser | null>(() => {
    const storedUser = Cookies.get('user');
    return storedUser ? JSON.parse(storedUser) : null;
  });

  useEffect(() => {
    const checkAuthState = async () => {
      try {
        const storedUser = Cookies.get('user');
        const sessionToken = Cookies.get('sessionToken');

        if (!storedUser || !sessionToken) {
          handleSignOut();
          return;
        }

        try {
          const currentUser = await getCurrentUser();
          const session = await fetchAuthSession();
          
          if (!session.tokens?.accessToken) {
            handleSignOut();
            return;
          }

          const userData = JSON.parse(storedUser);
          
          // セッショントークンを更新
          const tokenExpiration = new Date();
          tokenExpiration.setHours(tokenExpiration.getHours() + 1);
          
          Cookies.set('sessionToken', session.tokens.accessToken.toString(), {
            ...getCookieOptions(),
            expires: tokenExpiration
          });

          setIsAuthenticated(true);
          setUser(userData);

        } catch (error) {
          console.error('セッション検証エラー:', error);
          // セッションの再取得を試みる
          try {
            const userData = JSON.parse(storedUser);
            const { isSignedIn } = await amplifySignIn({ 
              username: userData.username,
              password: sessionToken 
            });

            if (isSignedIn) {
              const newSession = await fetchAuthSession();
              if (newSession.tokens?.accessToken) {
                const tokenExpiration = new Date();
                tokenExpiration.setHours(tokenExpiration.getHours() + 1);
                
                Cookies.set('sessionToken', newSession.tokens.accessToken.toString(), {
                  ...getCookieOptions(),
                  expires: tokenExpiration
                });

                setIsAuthenticated(true);
                setUser(userData);
                return;
              }
            }
          } catch (refreshError) {
            console.error('セッション再取得エラー:', refreshError);
          }
          handleSignOut();
        }
      } catch (error) {
        console.error('認証状態チェックエラー:', error);
        handleSignOut();
      }
    };

    checkAuthState();
  }, []);

  const handleSignIn = async (username: string, password: string) => {
    try {
      await amplifySignOut();
      const { isSignedIn } = await amplifySignIn({ username, password });

      if (isSignedIn) {
        const currentUser = await getCurrentUser();
        const session = await fetchAuthSession();
        
        if (session.tokens) {
          const userData = {
            username: currentUser.username,
            attributes: {
              email: currentUser.signInDetails?.loginId,
            },
            lastLogin: new Date().toISOString(),
          };

          // セッショントークンの有効期限を1時間に設定
          const tokenExpiration = new Date();
          tokenExpiration.setHours(tokenExpiration.getHours() + 1);

          // ユーザー情報とセッショントークンを保存
          Cookies.set('user', JSON.stringify(userData), getCookieOptions());
          
          if (session.tokens.accessToken) {
            Cookies.set('sessionToken', session.tokens.accessToken.toString(), {
              ...getCookieOptions(),
              expires: tokenExpiration
            });
          }

          setIsAuthenticated(true);
          setUser(userData);
        }
      }
    } catch (error) {
      console.error('サインインエラー:', error);
      throw error;
    }
  };

  const handleSignOut = async () => {
    try {
      await amplifySignOut();
    } catch (error) {
      console.error('サインアウトエラー:', error);
    } finally {
      setIsAuthenticated(false);
      setUser(null);
      Cookies.remove('user', { path: '/' });
      Cookies.remove('sessionToken', { path: '/' });
    }
  };

  return (
    <AuthContext.Provider value={{
      isAuthenticated,
      user,
      signIn: handleSignIn,
      signOut: handleSignOut
    }}>
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
