import React from 'react';
import { Box, Typography } from '@mui/material';
import { APP_CONFIG } from '../../config/constants';

interface NoDataMessageProps {
  message?: string;
}

export default function NoDataMessage({ message = '選択された日付のデータが見つかりません' }: NoDataMessageProps) {
  return (
    <Box sx={{ 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center', 
      minHeight: '200px',
      bgcolor: 'rgba(255, 255, 255, 0.05)',
      borderRadius: 2,
      p: 3
    }}>
      <Typography variant="h6" sx={{ color: APP_CONFIG.THEME.TEXT_SECONDARY }}>
        {message}
      </Typography>
    </Box>
  );
} 