import { useState } from 'react';
import { 
  resetPassword,
  confirmResetPassword
} from '@aws-amplify/auth';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Box,
  TextField,
  Button,
  Typography,
  Alert,
  Stepper,
  Step,
  StepLabel,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { APP_CONFIG } from '../../config/constants';

const textFieldStyles = {
  '& .MuiOutlinedInput-root': {
    color: APP_CONFIG.THEME.TEXT_PRIMARY,
    '& fieldset': {
      borderColor: `${APP_CONFIG.THEME.TEXT_SECONDARY}50`,
    },
    '&:hover fieldset': {
      borderColor: APP_CONFIG.THEME.TEXT_PRIMARY,
    },
    '&.Mui-focused fieldset': {
      borderColor: APP_CONFIG.THEME.PRIMARY_COLOR,
    },
  },
  '& .MuiInputLabel-root': {
    color: APP_CONFIG.THEME.TEXT_SECONDARY,
    '&.Mui-focused': {
      color: APP_CONFIG.THEME.PRIMARY_COLOR,
    },
  },
  '& .MuiIconButton-root': {
    color: APP_CONFIG.THEME.TEXT_SECONDARY,
  },
};

const buttonStyles = {
  bgcolor: APP_CONFIG.THEME.PRIMARY_COLOR,
  color: APP_CONFIG.THEME.TEXT_PRIMARY,
  '&:hover': {
    bgcolor: `${APP_CONFIG.THEME.PRIMARY_COLOR}CC`,
  },
  mt: 3,
  mb: 2
};

export default function PasswordReset() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [activeStep, setActiveStep] = useState(0);
  const [showPassword, setShowPassword] = useState(false);

  const steps = ['メールアドレスの入力', '確認コードの入力', '新しいパスワードの設定'];

  const handleSendCode = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await resetPassword({ username: email });
      setActiveStep(1);
      setSuccess('確認コードを送信しました。メールをご確認ください。');
    } catch (err: any) {
      setError(err.message || 'コードの送信に失敗しました');
    }
  };

  const handleSubmitNewPassword = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await confirmResetPassword({
        username: email,
        confirmationCode: code,
        newPassword
      });
      setSuccess('パスワードが正常に更新されました');
      setTimeout(() => navigate('/login'), 2000);
    } catch (err: any) {
      setError(err.message || 'パスワードの更新に失敗しました');
    }
  };

  const validatePassword = (password: string) => {
    const regex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[^A-Za-z0-9\s]|(?<=\S)\s(?=\S)).{8,}$/;
    return regex.test(password);
  };

  return (
    <Box sx={{ 
      minHeight: '100vh',
      bgcolor: APP_CONFIG.THEME.BACKGROUND_COLOR,
      display: 'flex',
      flexDirection: 'column'
    }}>
      <Container component="main" maxWidth="xs">
        <Box sx={{ 
          mt: 8, 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center',
          bgcolor: APP_CONFIG.THEME.SURFACE_COLOR,
          p: 4,
          borderRadius: 2,
          boxShadow: '0 4px 20px 0 rgba(0,0,0,0.12)'
        }}>
          <Box
            component="img"
            src="/mizlinx-logo.png"
            alt="MizLinx"
            sx={{
              width: '200px',
              mb: 4
            }}
          />

          <Typography 
            component="h1" 
            variant="h5" 
            sx={{ 
              mb: 3,
              color: APP_CONFIG.THEME.TEXT_PRIMARY
            }}
          >
            パスワードのリセット
          </Typography>

          <Stepper 
            activeStep={activeStep} 
            sx={{ 
              width: '100%', 
              mb: 4,
              '& .MuiStepLabel-label': {
                color: APP_CONFIG.THEME.TEXT_SECONDARY,
                '&.Mui-active': {
                  color: APP_CONFIG.THEME.TEXT_PRIMARY,
                },
              },
              '& .MuiStepIcon-root': {
                color: APP_CONFIG.THEME.TEXT_SECONDARY,
                '&.Mui-active': {
                  color: APP_CONFIG.THEME.PRIMARY_COLOR,
                },
                '&.Mui-completed': {
                  color: APP_CONFIG.THEME.PRIMARY_COLOR,
                },
              },
            }}
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          {error && <Alert severity="error" sx={{ mb: 2, width: '100%' }}>{error}</Alert>}
          {success && <Alert severity="success" sx={{ mb: 2, width: '100%' }}>{success}</Alert>}

          {activeStep === 0 && (
            <Box component="form" onSubmit={handleSendCode} sx={{ width: '100%' }}>
              <TextField
                sx={textFieldStyles}
                margin="normal"
                required
                fullWidth
                id="email"
                label="メールアドレス"
                name="email"
                autoComplete="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Button
                sx={buttonStyles}
                type="submit"
                fullWidth
                variant="contained"
              >
                確認コードを送信
              </Button>
            </Box>
          )}

          {activeStep === 1 && (
            <Box component="form" onSubmit={handleSubmitNewPassword} sx={{ width: '100%' }}>
              <TextField
                sx={textFieldStyles}
                margin="normal"
                required
                fullWidth
                id="code"
                label="確認コード"
                name="code"
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
              <TextField
                sx={textFieldStyles}
                margin="normal"
                required
                fullWidth
                name="newPassword"
                label="新しいパスワード"
                type={showPassword ? 'text' : 'password'}
                id="newPassword"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                error={newPassword !== '' && !validatePassword(newPassword)}
                helperText={
                  newPassword !== '' && !validatePassword(newPassword) ?
                  'パスワードは8文字以上で、大文字、小文字、数字、記号を含める必要があります' : ''
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="パスワードの表示切り替え"
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Button
                sx={buttonStyles}
                type="submit"
                fullWidth
                variant="contained"
                disabled={!validatePassword(newPassword)}
              >
                パスワードを更新
              </Button>
            </Box>
          )}

          <Button
            onClick={() => navigate('/login')}
            fullWidth
            sx={{
              color: APP_CONFIG.THEME.PRIMARY_COLOR,
              '&:hover': {
                bgcolor: `${APP_CONFIG.THEME.PRIMARY_COLOR}10`,
              },
              mt: 1
            }}
          >
            ログインページに戻る
          </Button>
        </Box>
      </Container>
    </Box>
  );
}
