import { useState, useEffect, useCallback, useMemo, cloneElement, forwardRef, useImperativeHandle } from 'react';
import { S3Client, ListObjectsV2Command, GetObjectCommand } from '@aws-sdk/client-s3';
import {
  Box,
  Typography,
  CircularProgress,
  Alert,
  Button,
  Card,
  CardContent,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Grid,
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import ja from 'date-fns/locale/ja';
import { startOfDay, endOfDay, format } from 'date-fns';
import { APP_CONFIG } from '../../config/constants';
import { commonSelectStyles, commonMenuItemStyles, filterContainerStyles } from '../../styles/commonStyles';
import { BatteryChargingFull, Thermostat, WbSunny, LocationOn, SignalCellular4Bar, SignalCellular3Bar, SignalCellular2Bar, SignalCellular1Bar, SignalCellular0Bar, AccessTime } from '@mui/icons-material';
import CircularMeter from '../common/CircularMeter';
import MetricsChart from '../common/MetricsChart';
import LocationMap from '../common/LocationMap';
import NoDataMessage from '../common/NoDataMessage';

interface DeviceData {
  sensor_data: {
    timestamp: string;
    sensors: any[];
    error?: string;
  };
  metrics_data: {
    next_alarm_time?: {
      timestamp: number;
      datetime: string;
    };
    battery_voltage_mV: number;
    solar_panel_voltage_mV: number;
    temperature: number | null;
    humidity: number | null;
    errors?: string[];
    GNSS: {
      timestamp?: string;
      latitude?: number;
      longitude?: number;
    } | null;
    lte: {
      'Signal Quality': string;
      'RSSI(dB)': number;
    };
    acceleration: {
      x: number | null;
      y: number | null;
      z: number | null;
    };
    device_settings: {
      wifi: {
        enabled: boolean;
        interface: string;
      };
      video: {
        resolution: [number, number];
        framerate: number;
        duration: number;
      };
      gnss: {
        port: string;
        baudrate: number;
        position_accuracy: number;
      };
      mechatrax: {
        quick_mode_interval: number;
        default_interval: number;
        next_wake_time: string;
      };
    };
  };
}

interface DeviceTabProps {
  deviceId: string;
}

const DeviceTab = forwardRef<{ handleRefresh: () => Promise<void> }, DeviceTabProps>(({ deviceId }, ref) => {
  const [deviceData, setDeviceData] = useState<DeviceData | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [availableDates, setAvailableDates] = useState<string[]>([]);
  const [selectedTime, setSelectedTime] = useState<string>('');
  const [availableData, setAvailableData] = useState<{ time: string; originalTime: string; data: DeviceData }[]>([]);
  const [lastKnownLocation, setLastKnownLocation] = useState<{
    latitude: number | null;
    longitude: number | null;
    timestamp: string | null;
  }>({
    latitude: null,
    longitude: null,
    timestamp: null
  });

  const s3Client = useMemo(() => new S3Client({
    region: APP_CONFIG.AWS_CONFIG.REGION,
    credentials: {
      accessKeyId: APP_CONFIG.AWS_CONFIG.ACCESS_KEY_ID!,
      secretAccessKey: APP_CONFIG.AWS_CONFIG.SECRET_ACCESS_KEY!,
    },
  }), []);

  const fetchDeviceData = useCallback(async (date: Date) => {
    if (!deviceId) return;
    setLoading(true);
    setError('');
    try {
      const dayStart = startOfDay(date);
      const dayEnd = endOfDay(date);
      
      const listCommand = new ListObjectsV2Command({
        Bucket: APP_CONFIG.S3_CONFIG.BUCKET_NAME,
        Prefix: `${APP_CONFIG.S3_CONFIG.BASE_PATH}/${deviceId}/${APP_CONFIG.PATHS.JSON}/`,
        MaxKeys: 1000
      });
      
      let allContents: any[] = [];
      let continuationToken: string | undefined;

      do {
        const result = await s3Client.send(listCommand);
        if (result.Contents) {
          allContents = [...allContents, ...result.Contents];
        }
        continuationToken = result.NextContinuationToken;
        if (continuationToken) {
          listCommand.input.ContinuationToken = continuationToken;
        }
      } while (continuationToken);

      if (allContents.length === 0) {
        console.log('DeviceTab - No contents found');
        setAvailableData([]);
        return;
      }

      const filteredContents = allContents
        .filter(item => {
          if (!item.LastModified) return false;
          const itemDate = new Date(item.LastModified);
          return itemDate >= dayStart && itemDate <= dayEnd;
        })
        .sort((a, b) => {
          const dateA = new Date(a.LastModified!).getTime();
          const dateB = new Date(b.LastModified!).getTime();
          return dateB - dateA;
        });

      console.log('DeviceTab - Filtered contents:', filteredContents.length);
      console.log('DeviceTab - First item LastModified:', filteredContents[0]?.LastModified);

      const dataPromises = filteredContents.map(async (item) => {
        if (!item.Key) return null;
        try {
          const getCommand = new GetObjectCommand({
            Bucket: APP_CONFIG.S3_CONFIG.BUCKET_NAME,
            Key: item.Key,
          });
          const response = await s3Client.send(getCommand);
          const jsonData = await response.Body?.transformToString();
          if (jsonData) {
            const lastModified = new Date(item.LastModified!);
            return {
              time: lastModified.toLocaleTimeString('ja-JP', {
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: false
              }),
              originalTime: item.LastModified!.toISOString(),
              data: JSON.parse(jsonData)
            };
          }
        } catch (err) {
          console.error(`DeviceTab - Error getting data for ${item.Key}:`, err);
        }
        return null;
      });

      const validData = (await Promise.all(dataPromises))
        .filter((item): item is { time: string; originalTime: string; data: DeviceData } => item !== null)
        .sort((a, b) => new Date(b.originalTime).getTime() - new Date(a.originalTime).getTime());

      console.log('DeviceTab - Valid data count:', validData.length);
      console.log('DeviceTab - Latest data time:', validData[0]?.originalTime);

      setAvailableData(validData);

      if (validData.length > 0) {
        setSelectedTime(validData[0].time);
        setDeviceData(validData[0].data);
      } else {
        setSelectedTime('');
        setDeviceData(null);
      }

    } catch (err) {
      console.error('Error fetching device data:', err);
      setError(`デバイスデータの取得に失敗しました: ${(err as any).message}`);
    } finally {
      setLoading(false);
    }
  }, [s3Client, deviceId]);

  const fetchAvailableDates = useCallback(async () => {
    try {
      const listCommand = new ListObjectsV2Command({
        Bucket: APP_CONFIG.S3_CONFIG.BUCKET_NAME,
        Prefix: `${APP_CONFIG.S3_CONFIG.BASE_PATH}/${deviceId}/${APP_CONFIG.PATHS.JSON}/`,
        MaxKeys: 1000
      });

      let allContents: any[] = [];
      let continuationToken: string | undefined;

      do {
        const result = await s3Client.send(listCommand);
        if (result.Contents) {
          allContents = [...allContents, ...result.Contents];
        }
        continuationToken = result.NextContinuationToken;
        if (continuationToken) {
          listCommand.input.ContinuationToken = continuationToken;
        }
      } while (continuationToken);

      if (allContents.length > 0) {
        const dates = allContents
          .filter(item => item.LastModified)
          .map(item => {
            const date = new Date(item.LastModified!);
            return date.toISOString().split('T')[0];
          })
          .sort((a, b) => new Date(b).getTime() - new Date(a).getTime());
        
        const uniqueDates = Array.from(new Set(dates));
        console.log('DeviceTab - 利用可能な日付:', uniqueDates);
        console.log('DeviceTab - 最新の日付:', uniqueDates[0]);
        
        setAvailableDates(uniqueDates);
        return uniqueDates;
      }
      return [];
    } catch (err) {
      console.error('利用可能な日付の取得に失敗:', err);
      return [];
    }
  }, [s3Client, deviceId]);

  const handleDateChange = (date: Date | null) => {
    if (date) {
      setSelectedDate(date);
      setSelectedTime('');
      fetchDeviceData(date);
    }
  };

  const handleTimeChange = (event: { target: { value: string } }) => {
    const selectedTimeData = availableData.find(item => item.time === event.target.value);
    if (selectedTimeData) {
      setSelectedTime(event.target.value);
      setDeviceData(selectedTimeData.data);
    }
  };

  useEffect(() => {
    fetchAvailableDates();
  }, [fetchAvailableDates]);

  useEffect(() => {
    fetchDeviceData(selectedDate);
  }, [fetchDeviceData, selectedDate]);

  useEffect(() => {
    console.log('Should show time selector:', availableData.length > 0);
  }, [availableData]);

  useEffect(() => {
    console.log('Available Data:', availableData);
    console.log('Selected Time:', selectedTime);
  }, [availableData, selectedTime]);

  useEffect(() => {
    console.log('Selected Time:', selectedTime);
  }, [selectedTime]);

  useEffect(() => {
    if (deviceData?.metrics_data.GNSS) {
      const { latitude, longitude, timestamp } = deviceData.metrics_data.GNSS;
      if (latitude !== undefined && longitude !== undefined) {
        setLastKnownLocation({
          latitude,
          longitude,
          timestamp: timestamp || new Date().toISOString()
        });
      }
    }
  }, [deviceData]);

  // バッテリー状態の判定
  const getBatteryStatus = (voltage: number): 'critical' | 'warning' | 'normal' | 'good' => {
    const voltageV = voltage / 1000;
    if (voltageV < 10.5) return 'critical';
    if (voltageV < 11.5) return 'warning';
    if (voltageV < 12.5) return 'normal';
    return 'good';
  };

  // バッテリー状態のテキストを修正
  const getBatteryStatusText = (voltage: number | null): string => {
    if (voltage === null) return '不明';
    const status = getBatteryStatus(voltage);
    switch (status) {
      case 'critical': return '要充電';
      case 'warning': return '低電圧';
      case 'normal': return '通常';
      case 'good': return '良好';
    }
  };

  // バッテリー状態の色
  const getBatteryStatusColor = (voltage: number): string => {
    const status = getBatteryStatus(voltage);
    switch (status) {
      case 'critical': return '#ff4444';
      case 'warning': return '#ffaa00';
      case 'normal': return '#44ff44';
      case 'good': return '#00ff00';
    }
  };

  // ソーラー出力状態の判定を修正
  const getSolarStatus = (voltage: number): 'warning' | 'normal' | 'good' => {
    const voltageV = voltage / 1000;
    if (voltageV < 10) return 'warning';
    if (voltageV < 15) return 'normal';
    return 'good';
  };

  // ソーラー出力状態のテキストを修正
  const getSolarStatusText = (voltage: number | null): string => {
    if (voltage === null) return '不明';
    const status = getSolarStatus(voltage);
    switch (status) {
      case 'warning': return '低出力';
      case 'normal': return '標準出力';
      case 'good': return '高出力';
    }
  };

  // ソーラー出力状態の色を修正
  const getSolarStatusColor = (voltage: number): string => {
    const status = getSolarStatus(voltage);
    switch (status) {
      case 'warning': return '#ffaa00';
      case 'normal': 
      case 'good': return '#44ff44';  // 'good'の場合も'normal'と同じ色を使用
    }
  };

  const handleRefresh = useCallback(async () => {
    if (!deviceId) return;
    setLoading(true);
    setError('');
    try {
      console.log('デバイスタブの更新を開始');
      // 現在の日付を取得
      const now = new Date();
      const today = startOfDay(now);
      
      // まず今日のデータを確認
      await fetchDeviceData(today);
      
      // もし今日のデータがない場合は、利用可能な最新の日付を探す
      if (availableData.length === 0) {
        const dates = await fetchAvailableDates();
        if (dates.length > 0) {
          const latestDate = new Date(dates[0]);
          setSelectedDate(latestDate);
          await fetchDeviceData(latestDate);
        }
      }
      console.log('デバイスタブの更新が完了');
    } catch (err) {
      console.error('デバイスデータの更新に失敗しました:', err);
      setError(`データの更新に失敗しました: ${(err as any).message}`);
    } finally {
      setLoading(false);
    }
  }, [deviceId, fetchDeviceData, fetchAvailableDates, availableData.length]);

  useImperativeHandle(ref, () => ({
    handleRefresh
  }), [handleRefresh]);

  useEffect(() => {
    if (deviceData) {
      console.log('LTE Data:', deviceData.metrics_data.lte);
    }
  }, [deviceData]);

  const getSignalStrengthIcon = (rssi: string | undefined) => {
    if (!rssi) return <SignalCellular0Bar sx={{ color: 'rgba(255, 255, 255, 0.87)' }} />;
    const value = parseInt(rssi);
    if (value >= -65) return <SignalCellular4Bar sx={{ color: 'rgba(255, 255, 255, 0.87)' }} />;
    if (value >= -75) return <SignalCellular3Bar sx={{ color: 'rgba(255, 255, 255, 0.87)' }} />;
    if (value >= -85) return <SignalCellular2Bar sx={{ color: 'rgba(255, 255, 255, 0.87)' }} />;
    if (value >= -95) return <SignalCellular1Bar sx={{ color: 'rgba(255, 255, 255, 0.87)' }} />;
    return <SignalCellular0Bar sx={{ color: 'rgba(255, 255, 255, 0.87)' }} />;
  };

  const getSignalStrengthText = (rssi: string | undefined) => {
    if (!rssi) return '弱';
    const value = parseInt(rssi);
    if (value >= -65) return '極めて良好';
    if (value >= -75) return '良好';
    if (value >= -85) return '普通';
    if (value >= -95) return '弱い';
    return '極めて弱い';
  };

  useEffect(() => {
    if (deviceId) {
      fetchAvailableDates();
    }
  }, [deviceId, fetchAvailableDates]);

  const handleDownloadCSV = useCallback(async (type: 'current' | 'power' | 'environment') => {
    if (!deviceData || availableData.length === 0) return;

    try {
      let headers: string[];
      let dataRows: string[];

      switch (type) {
        case 'current':
          // 現在のデータのCSV
          headers = [
            '時刻',
            'バッテリー電圧(V)',
            'ソーラーパネル電圧(V)',
            '温度(℃)',
            '湿度(%)',
            '緯度',
            '経度',
            'LTE信号強度(dB)',
          ];
          dataRows = [
            [
              selectedTime,
              (deviceData.metrics_data.battery_voltage_mV / 1000).toFixed(2),
              (deviceData.metrics_data.solar_panel_voltage_mV / 1000).toFixed(2),
              deviceData.metrics_data.temperature?.toFixed(1) ?? '',
              deviceData.metrics_data.humidity?.toFixed(1) ?? '',
              deviceData.metrics_data.GNSS?.latitude ?? '',
              deviceData.metrics_data.GNSS?.longitude ?? '',
              deviceData.metrics_data.lte?.['RSSI(dB)'] ?? '',
            ].join(',')
          ];
          break;

        case 'power':
          // 電源情報の推移
          headers = [
            '時刻',
            'バッテリー電圧(V)',
            'ソーラーパネル電圧(V)',
          ];
          dataRows = availableData.map(item => [
            new Date(item.originalTime).toLocaleString('ja-JP'),
            (item.data.metrics_data.battery_voltage_mV / 1000).toFixed(2),
            (item.data.metrics_data.solar_panel_voltage_mV / 1000).toFixed(2),
          ].join(','));
          break;

        case 'environment':
          // 環境情報の推移
          headers = [
            '時刻',
            '温度(℃)',
            '湿度(%)',
          ];
          dataRows = availableData.map(item => [
            new Date(item.originalTime).toLocaleString('ja-JP'),
            item.data.metrics_data.temperature?.toFixed(1) ?? '',
            item.data.metrics_data.humidity?.toFixed(1) ?? '',
          ].join(','));
          break;
      }

      const csvContent = `${headers.join(',')}\n${dataRows.join('\n')}`;
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', `device_${type}_${format(selectedDate, 'yyyyMMdd')}.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('CSVダウンロードエラー:', error);
      setError('CSVファイルの作成に失敗しました');
    }
  }, [deviceData, selectedDate, selectedTime, availableData]);

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Typography variant="h6">デバイス情報</Typography>
        <Button 
          variant="contained" 
          onClick={handleRefresh}
          disabled={loading}
        >
          更新
        </Button>
      </Box>

      <Box sx={filterContainerStyles}>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
          <DatePicker
            label="日付でフィルター"
            value={selectedDate}
            onChange={handleDateChange}
            shouldDisableDate={(date) => {
              return !availableDates.includes(date.toISOString().split('T')[0])
            }}
            sx={commonSelectStyles}
          />
        </LocalizationProvider>

        {availableData.length > 0 && (
          <FormControl>
            <InputLabel id="time-select-label" sx={{ color: 'white' }}>時刻を選択</InputLabel>
            <Select
              labelId="time-select-label"
              value={selectedTime}
              onChange={handleTimeChange}
              label="時刻を選択"
              sx={commonSelectStyles}
            >
              {availableData.map((item) => (
                <MenuItem
                  key={item.time}
                  value={item.time}
                  sx={commonMenuItemStyles}
                >
                  {item.time}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Box>

      {loading && (
        <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
          <CircularProgress />
        </Box>
      )}

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      {!loading && !error && (!deviceData || availableData.length === 0) && (
        <NoDataMessage />
      )}

      {!loading && !error && deviceData && (
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box>
              <Card sx={{
                background: `linear-gradient(135deg, ${APP_CONFIG.THEME.SURFACE_COLOR} 0%, #2a3042 100%)`,
                boxShadow: '0 4px 20px 0 rgba(0,0,0,0.12)',
                borderRadius: 2,
              }}>
                <CardContent>
                  <Typography variant="h6" sx={{ 
                    color: APP_CONFIG.THEME.PRIMARY_COLOR,
                    fontWeight: 'bold',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    mb: 3
                  }}>
                    <BatteryChargingFull /> 電源情報
                  </Typography>
                  
                  <Box sx={{ mb: 4 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 4, justifyContent: 'flex-start' }}>
                      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 1 }}>
                        <Typography variant="body2" color={APP_CONFIG.THEME.TEXT_SECONDARY}>
                          バッテリー電圧
                        </Typography>
                        <CircularMeter
                          value={deviceData.metrics_data.battery_voltage_mV ? deviceData.metrics_data.battery_voltage_mV / 1000 : null}
                          maxValue={14.4}
                          unit="V"
                          icon={<BatteryChargingFull fontSize="large" />}
                          status={deviceData.metrics_data.battery_voltage_mV ? getBatteryStatus(deviceData.metrics_data.battery_voltage_mV) : 'normal'}
                          size={80}
                        />
                      </Box>
                      <Box>
                        <Typography variant="body2" sx={{ 
                          color: getBatteryStatusColor(deviceData.metrics_data.battery_voltage_mV),
                          fontWeight: 'bold'
                        }}>
                          {getBatteryStatusText(deviceData.metrics_data.battery_voltage_mV)}
                        </Typography>
                        <Typography variant="caption" color={APP_CONFIG.THEME.TEXT_SECONDARY}>
                          動作範囲: 11.5V - 14.4V
                        </Typography>
                      </Box>
                    </Box>
                  </Box>

                  <Box>
                    {/*
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 4, justifyContent: 'flex-start' }}>
                      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 1 }}>
                        <Typography variant="body2" color={APP_CONFIG.THEME.TEXT_SECONDARY}>
                          ソーラーパネル電圧
                        </Typography>
                        <CircularMeter
                          value={deviceData.metrics_data.solar_panel_voltage_mV ? deviceData.metrics_data.solar_panel_voltage_mV / 1000 : null}
                          maxValue={21.0}
                          unit="V"
                          icon={<WbSunny fontSize="large" />}
                          status={deviceData.metrics_data.solar_panel_voltage_mV ? getSolarStatus(deviceData.metrics_data.solar_panel_voltage_mV) : 'normal'}
                          size={80}
                        />
                      </Box>
                      <Box>
                        <Typography variant="body2" sx={{ 
                          color: getSolarStatusColor(deviceData.metrics_data.solar_panel_voltage_mV),
                          fontWeight: 'bold'
                        }}>
                          {getSolarStatusText(deviceData.metrics_data.solar_panel_voltage_mV)}
                        </Typography>
                        <Typography variant="caption" color={APP_CONFIG.THEME.TEXT_SECONDARY}>
                          最大出力: 21.0V
                        </Typography>
                      </Box>
                    </Box>
                    */}
                  </Box>
                </CardContent>
              </Card>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box>
              <Card sx={{
                background: `linear-gradient(135deg, ${APP_CONFIG.THEME.SURFACE_COLOR} 0%, #2a3042 100%)`,
                boxShadow: '0 4px 20px 0 rgba(0,0,0,0.12)',
                borderRadius: 2,
              }}>
                <CardContent>
                  <Typography variant="h6" sx={{ 
                    color: APP_CONFIG.THEME.PRIMARY_COLOR,
                    fontWeight: 'bold',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    mb: 3
                  }}>
                    <Thermostat /> 環境情報
                  </Typography>
                  
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <Box>
                      <Typography variant="body2" color={APP_CONFIG.THEME.TEXT_SECONDARY}>
                        温度
                      </Typography>
                      <Typography variant="h4" sx={{ 
                        color: APP_CONFIG.THEME.TEXT_PRIMARY,
                        fontWeight: 'medium'
                      }}>
                        {deviceData.metrics_data.temperature !== null && deviceData.metrics_data.temperature !== undefined
                          ? `${deviceData.metrics_data.temperature.toFixed(1)}℃`
                          : '不明'}
                      </Typography>
                    </Box>
                    
                    <Box>
                      <Typography variant="body2" color={APP_CONFIG.THEME.TEXT_SECONDARY}>
                        湿度
                      </Typography>
                      <Typography variant="h4" sx={{ 
                        color: APP_CONFIG.THEME.TEXT_PRIMARY,
                        fontWeight: 'medium'
                      }}>
                        {deviceData.metrics_data.humidity !== null && deviceData.metrics_data.humidity !== undefined
                          ? `${deviceData.metrics_data.humidity.toFixed(1)}%`
                          : '不明'}
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box>
              <Card sx={{
                background: `linear-gradient(135deg, ${APP_CONFIG.THEME.SURFACE_COLOR} 0%, #2a3042 100%)`,
                boxShadow: '0 4px 20px 0 rgba(0,0,0,0.12)',
                borderRadius: 2,
              }}>
                <CardContent>
                  <Typography variant="h6" sx={{ 
                    color: APP_CONFIG.THEME.PRIMARY_COLOR,
                    fontWeight: 'bold',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    mb: 3
                  }}>
                    <LocationOn /> 位置情報
                  </Typography>
                  
                  <LocationMap
                    latitude={deviceData.metrics_data.GNSS?.latitude ?? lastKnownLocation.latitude}
                    longitude={deviceData.metrics_data.GNSS?.longitude ?? lastKnownLocation.longitude}
                    lastUpdate={deviceData.metrics_data.GNSS?.timestamp ?? lastKnownLocation.timestamp}
                    currentCoordinates={{
                      latitude: deviceData.metrics_data.GNSS?.latitude ?? null,
                      longitude: deviceData.metrics_data.GNSS?.longitude ?? null
                    }}
                  />
                  
                  {deviceData.metrics_data.GNSS?.latitude != null && 
                   deviceData.metrics_data.GNSS?.longitude != null && (
                    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
                      <Typography variant="body2" color={APP_CONFIG.THEME.TEXT_SECONDARY}>
                        緯度: {deviceData.metrics_data.GNSS.latitude?.toFixed(6)} / 
                        経度: {deviceData.metrics_data.GNSS.longitude?.toFixed(6)}
                      </Typography>
                    </Box>
                  )}
                </CardContent>
              </Card>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box>
              <Card sx={{
                background: `linear-gradient(135deg, ${APP_CONFIG.THEME.SURFACE_COLOR} 0%, #2a3042 100%)`,
                boxShadow: '0 4px 20px 0 rgba(0,0,0,0.12)',
                borderRadius: 2,
              }}>
                <CardContent>
                  <Typography variant="h6" sx={{ 
                    color: APP_CONFIG.THEME.PRIMARY_COLOR,
                    fontWeight: 'bold',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    mb: 3
                  }}>
                    <SignalCellular4Bar /> 通信品質
                  </Typography>
                  
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <Box>
                      <Typography variant="body2" color={APP_CONFIG.THEME.TEXT_SECONDARY}>
                        電波強度
                      </Typography>
                      <Box sx={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        gap: 2,
                        mt: 1,
                        mb: 0.5 
                      }}>
                        <Box sx={{ 
                          display: 'flex', 
                          alignItems: 'center', 
                          justifyContent: 'center',
                          width: 48,
                          height: 48,
                          borderRadius: '50%',
                          bgcolor: 'rgba(255, 255, 255, 0.05)'
                        }}>
                          {cloneElement(getSignalStrengthIcon(deviceData.metrics_data.lte?.['RSSI(dB)']?.toString()), {
                            sx: { 
                              fontSize: 32,
                              color: 'rgba(255, 255, 255, 0.87)'
                            }
                          })}
                        </Box>
                        <Box>
                          <Typography variant="body1" sx={{ 
                            color: APP_CONFIG.THEME.TEXT_PRIMARY,
                            fontWeight: 'medium',
                            mb: 0.5
                          }}>
                            {getSignalStrengthText(deviceData.metrics_data.lte?.['RSSI(dB)']?.toString())}
                          </Typography>
                          <Typography variant="caption" sx={{ color: APP_CONFIG.THEME.TEXT_SECONDARY }}>
                            {deviceData.metrics_data.lte?.['RSSI(dB)']} dBm
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    
                    <Box sx={{ mt: 3 }}>
                      <Typography variant="body2" color={APP_CONFIG.THEME.TEXT_SECONDARY}>
                        電波品質
                      </Typography>
                      <Typography variant="h5" sx={{ 
                        color: APP_CONFIG.THEME.TEXT_PRIMARY,
                        fontWeight: 'medium',
                        mt: 1
                      }}>
                        {deviceData.metrics_data.lte?.['Signal Quality'] || '未取得'}
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={{ 
              width: '100%',
              overflow: 'auto',
              '& .recharts-wrapper': {
                width: '100% !important',
                minWidth: { xs: '400px', sm: '100%' },
                '& .recharts-surface': {
                  width: '100%',
                }
              }
            }}>
              <MetricsChart
                data={availableData.map(item => ({
                  time: item.originalTime,
                  battery_voltage_mV: item.data.metrics_data.battery_voltage_mV || undefined,
                  solar_panel_voltage_mV: item.data.metrics_data.solar_panel_voltage_mV || undefined,
                  temperature: item.data.metrics_data.temperature || undefined,
                  humidity: item.data.metrics_data.humidity || undefined
                }))}
                type="power"
                selectedDate={selectedDate}
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box>
              <MetricsChart
                data={availableData.map(item => ({
                  time: item.originalTime,
                  temperature: item.data.metrics_data.temperature || undefined,
                  humidity: item.data.metrics_data.humidity || undefined
                }))}
                type="environment"
                selectedDate={selectedDate}
              />
            </Box>
          </Grid>
        </Grid>
      )}

      {!loading && !error && deviceData && (
        <Box sx={{ 
          mt: 3, 
          display: 'flex', 
          alignItems: 'center',
          gap: 1,
          color: APP_CONFIG.THEME.TEXT_SECONDARY
        }}>
          <AccessTime fontSize="small" />
          <Typography variant="body2">
            次回起動予定時刻：
            {deviceData.metrics_data.next_alarm_time?.datetime
              ? deviceData.metrics_data.next_alarm_time.datetime
              : '未設定'}
          </Typography>
        </Box>
      )}
    </Box>
  );
});

export default DeviceTab;
