import { Box, Grid } from '@mui/material';
import VideoTab from './VideoTab';
import SensorTab from './SensorTab';
import DeviceTab from './DeviceTab';
import { APP_CONFIG } from '../../config/constants';

interface ConsoleTabProps {
  deviceId: string;
}

export default function ConsoleTab({ deviceId }: ConsoleTabProps) {
  return (
    <Box sx={{ p: 3 }}>
      <Grid container spacing={2}>
        {/* 左側：動画とセンサーデータ */}
        <Grid item xs={12} md={6}>
          <Box sx={{ 
            bgcolor: APP_CONFIG.THEME.SURFACE_COLOR,
            borderRadius: 2,
            p: 2,
            mb: 2
          }}>
            <VideoTab deviceId={deviceId} />
          </Box>
          <Box sx={{ 
            bgcolor: APP_CONFIG.THEME.SURFACE_COLOR,
            borderRadius: 2,
            p: 2
          }}>
            <SensorTab deviceId={deviceId} />
          </Box>
        </Grid>

        {/* 右側：デバイスデータ */}
        <Grid item xs={12} md={6}>
          <Box sx={{ 
            bgcolor: APP_CONFIG.THEME.SURFACE_COLOR,
            borderRadius: 2,
            p: 2,
            height: '100%'
          }}>
            <DeviceTab deviceId={deviceId} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
} 