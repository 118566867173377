import { useState, useEffect, useCallback, useMemo, memo, forwardRef, useImperativeHandle } from 'react';
import { S3Client, ListObjectsV2Command, GetObjectCommand } from '@aws-sdk/client-s3';
import {
  Box,
  Typography,
  CircularProgress,
  Alert,
  Button,
  Card,
  CardContent,
  Grid,
  Select,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import ja from 'date-fns/locale/ja';
import { startOfDay, endOfDay, format } from 'date-fns';
import { APP_CONFIG } from '../../config/constants';
import { commonSelectStyles, filterContainerStyles } from '../../styles/commonStyles';
import MetricsChart from '../common/MetricsChart';
import { TrendingUp, TrendingDown, TrendingFlat } from '@mui/icons-material';
import NoDataMessage from '../common/NoDataMessage';

interface SensorData {
  sensor_data: {
    timestamp: string;
    sensors: Array<{
      water_temp?: number;
      dissolved_oxygen?: number;
      chlorophyll?: number;
      turbidity?: number;
    }>;
    error?: string;
  };
  metrics_data: {
    battery_voltage_mV: number;
    solar_panel_voltage_mV: number;
    temperature: number;
    humidity: number;
    GNSS: any;
    errors?: string[];
    lte: any;
    acceleration: {
      x: number | null;
      y: number | null;
      z: number | null;
    };
    device_settings: any;
  };
}

const getSensorValues = (data: SensorData | undefined) => {
  if (!data || !data.sensor_data || !data.sensor_data.sensors || data.sensor_data.error) {
    return {
      water_temp: null,
      dissolved_oxygen: null,
      chlorophyll: null,
      turbidity: null,
      error: data?.sensor_data?.error
    };
  }

  const sensor = data.sensor_data.sensors[0];
  return {
    water_temp: sensor?.water_temp ?? null,
    dissolved_oxygen: sensor?.dissolved_oxygen ?? null,
    chlorophyll: sensor?.chlorophyll ?? null,
    turbidity: sensor?.turbidity ?? null,
    error: undefined
  };
};

interface SensorCardProps {
  title: string;
  value: number | null;
  unit: string;
  trend: 'up' | 'down' | 'flat';
  gradient: string;
  sensorData: SensorData | null;
  availableData: { time: string; data: SensorData }[];
  getTrendIcon: (trend: 'up' | 'down' | 'flat', color: string) => JSX.Element;
  memoizedGetTrend: (current: number, previous: number | undefined) => 'up' | 'down' | 'flat';
}

const SensorCard = memo(({ title, value, unit, trend, gradient, sensorData, availableData, getTrendIcon, memoizedGetTrend }: SensorCardProps) => {
  return (
    <Card sx={{
      background: `linear-gradient(135deg, ${APP_CONFIG.THEME.SURFACE_COLOR} 0%, ${gradient} 100%)`,
      borderRadius: 2,
      boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
      backdropFilter: 'blur(4px)',
      border: '1px solid rgba(255, 255, 255, 0.18)',
      position: 'relative',
      overflow: 'hidden',
    }}>
      <CardContent>
        <Box sx={{ position: 'relative', zIndex: 1, p: 1 }}>
          <Typography variant="overline" sx={{ color: 'rgba(255,255,255,0.7)', letterSpacing: 1, fontWeight: 500, fontSize: '1.1rem', textTransform: 'none' }}>
            {title}
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 2 }}>
            <Typography variant="h3" sx={{ color: '#fff', fontWeight: 600, textShadow: '2px 2px 4px rgba(0,0,0,0.2)' }}>
              {value?.toFixed(1) ?? '---'}
              <Typography component="span" variant="h6" sx={{ ml: 1, color: 'rgba(255,255,255,0.7)' }}>
                {unit}
              </Typography>
            </Typography>
            {sensorData?.sensor_data?.error === undefined && (
              <Box sx={{ transform: 'scale(1.5)' }}>
                {getTrendIcon(
                  memoizedGetTrend(
                    getSensorValues(sensorData || undefined).water_temp ?? 0,
                    getSensorValues(availableData[1]?.data)?.water_temp ?? undefined
                  ),
                  '#fff'
                )}
              </Box>
            )}
          </Box>
          {sensorData?.sensor_data?.error && (
            <Typography color="error" sx={{ mt: 2 }}>
              {sensorData.sensor_data.error}
            </Typography>
          )}
        </Box>
      </CardContent>
    </Card>
  );
});

interface SensorTabProps {
  deviceId: string;
}

const SensorTab = forwardRef<{ handleRefresh: () => Promise<void> }, SensorTabProps>(({ deviceId }, ref) => {
  const [sensorData, setSensorData] = useState<SensorData | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [selectedTime, setSelectedTime] = useState<string>('');
  const [availableDates, setAvailableDates] = useState<string[]>([]);
  const [availableData, setAvailableData] = useState<{ time: string; data: SensorData }[]>([]);

  const s3Client = useMemo(() => new S3Client({
    region: APP_CONFIG.AWS_CONFIG.REGION,
    credentials: {
      accessKeyId: APP_CONFIG.AWS_CONFIG.ACCESS_KEY_ID!,
      secretAccessKey: APP_CONFIG.AWS_CONFIG.SECRET_ACCESS_KEY!,
    },
  }), []);

  const fetchSensorData = useCallback(async (date: Date) => {
    if (!deviceId) return;
    setLoading(true);
    setError('');
    try {
      const dayStart = startOfDay(date);
      const dayEnd = endOfDay(date);
      
      const listCommand = new ListObjectsV2Command({
        Bucket: APP_CONFIG.S3_CONFIG.BUCKET_NAME,
        Prefix: `${APP_CONFIG.S3_CONFIG.BASE_PATH}/${deviceId}/${APP_CONFIG.PATHS.JSON}/${format(date, 'yyyy-MM-dd')}`,
      });
      
      const result = await s3Client.send(listCommand);

      if (!result.Contents || result.Contents.length === 0) {
        setAvailableData([]);
        return;
      }

      const filteredContents = result.Contents
        .filter(item => {
          const itemDate = new Date(item.LastModified || Date.now());
          return itemDate >= dayStart && itemDate <= dayEnd;
        })
        .sort((a, b) => {
          const dateA = new Date(a.LastModified || 0).getTime();
          const dateB = new Date(b.LastModified || 0).getTime();
          return dateB - dateA;
        });

      const dataPromises = filteredContents.map(async (item) => {
        if (!item.Key) return null;
        try {
          const getCommand = new GetObjectCommand({
            Bucket: APP_CONFIG.S3_CONFIG.BUCKET_NAME,
            Key: item.Key,
          });
          const response = await s3Client.send(getCommand);
          const jsonData = await response.Body?.transformToString();
          if (jsonData) {
            return {
              time: item.LastModified!.toISOString(),
              data: JSON.parse(jsonData)
            };
          }
        } catch (err) {
          console.error(`Error getting data for ${item.Key}:`, err);
        }
        return null;
      });

      const validData = (await Promise.all(dataPromises))
        .filter((item): item is { time: string; data: SensorData } => item !== null);

      setAvailableData(validData);

      if (validData.length > 0) {
        setSensorData(validData[0].data);
      }

    } catch (err) {
      console.error('Error fetching sensor data:', err);
      setError(`センサーデータの取得に失敗しました: ${(err as any).message}`);
    } finally {
      setLoading(false);
    }
  }, [s3Client, deviceId]);

  const fetchAvailableDates = useCallback(async () => {
    try {
      const listCommand = new ListObjectsV2Command({
        Bucket: APP_CONFIG.S3_CONFIG.BUCKET_NAME,
        Prefix: `${APP_CONFIG.S3_CONFIG.BASE_PATH}/${deviceId}/${APP_CONFIG.PATHS.JSON}/`,
      });

      const result = await s3Client.send(listCommand);

      if (result.Contents) {
        const dates = result.Contents
          .filter(item => item.LastModified)
          .map(item => new Date(item.LastModified!).toISOString().split('T')[0])
          .sort((a, b) => new Date(b).getTime() - new Date(a).getTime());
        
        console.log('SensorTab - Available dates:', dates);
        console.log('SensorTab - Latest date:', dates[0]);
        
        setAvailableDates(Array.from(new Set(dates)));
        return dates;
      }
      console.log('SensorTab - No contents found');
      return [];
    } catch (err) {
      console.error('SensorTab - Error fetching available dates:', err);
      return [];
    }
  }, [s3Client, deviceId]);

  useEffect(() => {
    if (deviceId) {
      fetchAvailableDates();
    }
  }, [deviceId, fetchAvailableDates]);

  const handleDateChange = (date: Date | null) => {
    if (date) {
      setSelectedDate(date);
      fetchSensorData(date);
    }
  };

  useEffect(() => {
    fetchSensorData(selectedDate);
  }, [fetchSensorData, selectedDate]);

  const handleRefresh = useCallback(async () => {
    if (!deviceId) return;
    setLoading(true);
    setError('');
    try {
      console.log('センサータブの更新を開始');
      // 現在の日付を取得
      const now = new Date();
      const today = startOfDay(now);
      
      // まず今日のデータを確認
      await fetchSensorData(today);
      
      // もし今日のデータがない場合は、利用可能な最新の日付を探す
      if (availableData.length === 0) {
        const dates = await fetchAvailableDates();
        if (dates.length > 0) {
          const latestDate = new Date(dates[0]);
          setSelectedDate(latestDate);
          await fetchSensorData(latestDate);
        }
      }
      console.log('センサータブの更新が完了');
    } catch (err) {
      console.error('センサーデータの更新に失敗しました:', err);
      setError(`データの更新に失敗しました: ${(err as any).message}`);
    } finally {
      setLoading(false);
    }
  }, [deviceId, fetchSensorData, fetchAvailableDates, availableData.length]);

  useImperativeHandle(ref, () => ({
    handleRefresh
  }), [handleRefresh]);

  const getTrend = (current: number, previous: number | undefined): 'up' | 'down' | 'flat' => {
    if (previous === undefined) return 'flat';
    const diff = current - previous;
    if (Math.abs(diff) < 0.1) return 'flat';
    return diff > 0 ? 'up' : 'down';
  };

  const getTrendIcon = (trend: 'up' | 'down' | 'flat', color: string) => {
    switch (trend) {
      case 'up':
        return <TrendingUp sx={{ color }} />;
      case 'down':
        return <TrendingDown sx={{ color }} />;
      default:
        return <TrendingFlat sx={{ color }} />;
    }
  };

  useEffect(() => {
    if (availableData.length > 0) {
      const latestTime = availableData[0].time;
      setSelectedTime(latestTime);
      setSensorData(availableData[0].data);
    } else {
      setSelectedTime('');
      setSensorData(null);
    }
  }, [availableData]);

  const handleTimeChange = (event: SelectChangeEvent<string>) => {
    const time = event.target.value;
    setSelectedTime(time);
    const selectedData = availableData.find(item => item.time === time);
    if (selectedData) {
      setSensorData(selectedData.data);
    }
  };

  const memoizedGetTrend = useMemo(() => {
    return (current: number, previous: number | undefined): 'up' | 'down' | 'flat' => getTrend(current, previous);
  }, []);

  const memoizedGetSensorValues = useMemo(() => {
    return (data: SensorData | undefined) => getSensorValues(data);
  }, []);

  const chartData = useMemo(() => {
    return availableData.map(item => {
      const sensorValues = memoizedGetSensorValues(item?.data);
      return {
        time: item.time,
        water_temp: sensorValues.water_temp ?? undefined,
        dissolved_oxygen: sensorValues.dissolved_oxygen ?? undefined,
        chlorophyll: sensorValues.chlorophyll ?? undefined
      };
    });
  }, [availableData, memoizedGetSensorValues]);

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Typography variant="h6">水質センサーデータ</Typography>
        <Button 
          variant="contained" 
          onClick={handleRefresh}
          disabled={loading}
        >
          更新
        </Button>
      </Box>

      <Box sx={filterContainerStyles}>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
          <DatePicker
            label="日付でフィルター"
            value={selectedDate}
            onChange={handleDateChange}
            shouldDisableDate={(date) => !availableDates.includes(date.toISOString().split('T')[0])}
            sx={commonSelectStyles}
          />
        </LocalizationProvider>
        <Select
          value={selectedTime}
          onChange={handleTimeChange}
          sx={commonSelectStyles}
          displayEmpty
        >
          {availableData.map((item) => (
            <MenuItem key={item.time} value={item.time}>
              {format(new Date(item.time), 'HH:mm', { locale: ja })}
            </MenuItem>
          ))}
        </Select>
      </Box>

      {loading && (
        <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
          <CircularProgress />
        </Box>
      )}

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      {!loading && !error && (!sensorData || availableData.length === 0) && (
        <NoDataMessage />
      )}

      {!loading && !error && sensorData && availableData.length > 0 && (
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <SensorCard
              title="水温"
              value={memoizedGetSensorValues(sensorData).water_temp}
              unit="℃"
              trend={memoizedGetTrend(
                memoizedGetSensorValues(sensorData).water_temp ?? 0,
                memoizedGetSensorValues(availableData[1]?.data)?.water_temp ?? undefined
              )}
              gradient={APP_CONFIG.THEME.SURFACE_COLOR}
              sensorData={sensorData}
              availableData={availableData}
              getTrendIcon={getTrendIcon}
              memoizedGetTrend={memoizedGetTrend}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <SensorCard
              title="溶存酸素"
              value={memoizedGetSensorValues(sensorData).dissolved_oxygen}
              unit="mg/L"
              trend={memoizedGetTrend(
                memoizedGetSensorValues(sensorData).dissolved_oxygen ?? 0,
                memoizedGetSensorValues(availableData[1]?.data)?.dissolved_oxygen ?? undefined
              )}
              gradient="#1b5e20"
              sensorData={sensorData}
              availableData={availableData}
              getTrendIcon={getTrendIcon}
              memoizedGetTrend={memoizedGetTrend}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <SensorCard
              title="クロロフィル"
              value={memoizedGetSensorValues(sensorData).chlorophyll}
              unit="μg/L"
              trend={memoizedGetTrend(
                memoizedGetSensorValues(sensorData).chlorophyll ?? 0,
                memoizedGetSensorValues(availableData[1]?.data)?.chlorophyll ?? undefined
              )}
              gradient="#e65100"
              sensorData={sensorData}
              availableData={availableData}
              getTrendIcon={getTrendIcon}
              memoizedGetTrend={memoizedGetTrend}
            />
          </Grid>

          <Grid item xs={12}>
            <MetricsChart
              data={chartData}
              type="sensor"
              selectedDate={selectedDate}
            />
          </Grid>
        </Grid>
      )}
    </Box>
  );
});

export default SensorTab;
