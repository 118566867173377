import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { keyframes } from '@mui/system';

const fadeInScale = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  50% {
    opacity: 0.5;
    transform: scale(1.05);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

interface SplashScreenProps {
  onLoadComplete?: () => void;
}

export default function SplashScreen({ onLoadComplete }: SplashScreenProps) {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
      if (onLoadComplete) {
        onLoadComplete();
      }
    }, 2000);

    return () => clearTimeout(timer);
  }, [onLoadComplete]);

  if (!isLoading) return null;

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: '#1a1b26',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 9999,
        animation: `${fadeOut} 0.5s ease-out 2s forwards`,
      }}
    >
      <Box
        component="img"
        src="/mizlinx-logo.png"
        alt="MizLinx"
        sx={{
          width: { xs: '280px', sm: '320px', md: '400px' },
          maxWidth: '80%',
          height: 'auto',
          animation: `${fadeInScale} 1.2s ease-out forwards`,
        }}
      />
    </Box>
  );
} 