import { useState, useCallback } from 'react';
import { Box } from '@mui/material';
import Navigation from '../common/Navigation';
import Footer from '../common/Footer';
import ConsoleTab from './ConsoleTab';
import { APP_CONFIG } from '../../config/constants';

export default function Dashboard() {
  const [currentTab] = useState(0);
  const [selectedDeviceId, setSelectedDeviceId] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [enabledTabs] = useState([
    { component: ConsoleTab, enabled: true, ref: null },
  ]);

  const handleTabChange = () => {
    // Consoleタブのみなので何もしない
  };

  const handleRefreshAll = useCallback(async () => {
    if (!selectedDeviceId) return;
    try {
      setIsLoading(true);
      console.log('全データの更新を開始します');
      // Consoleタブのみなので特に更新処理は不要
    } catch (error) {
      console.error('データの更新中にエラーが発生しました:', error);
    } finally {
      setIsLoading(false);
    }
  }, [selectedDeviceId]);

  const handleDeviceChange = useCallback(async (deviceId: string) => {
    setSelectedDeviceId(deviceId);
    await handleRefreshAll();
  }, [handleRefreshAll]);

  const renderTabContent = () => {
    return <ConsoleTab deviceId={selectedDeviceId} />;
  };

  return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column', 
      minHeight: '100vh',
      bgcolor: APP_CONFIG.THEME.BACKGROUND_COLOR,
      color: APP_CONFIG.THEME.TEXT_PRIMARY 
    }}>
      <Navigation 
        currentTab={currentTab} 
        onTabChange={handleTabChange} 
        onRefreshAll={handleRefreshAll}
        deviceId={selectedDeviceId}
        onDeviceChange={handleDeviceChange}
        isLoading={isLoading}
        enabledTabs={enabledTabs}
      />
      <Box sx={{ flexGrow: 1, p: 3 }}>
        {renderTabContent()}
      </Box>
      <Footer />
    </Box>
  );
} 