import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import { Box, Typography } from '@mui/material';
import { APP_CONFIG } from '../../config/constants';
import 'leaflet/dist/leaflet.css';
import { useEffect } from 'react';
import L from 'leaflet';
import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

interface LocationMapProps {
  latitude: number | null;
  longitude: number | null;
  onMarkerClick?: () => void;
  lastUpdate?: string | null;
  currentCoordinates: {
    latitude: number | null;
    longitude: number | null;
  };
}

const DARK_STYLE_URL = 'https://{s}.basemaps.cartocdn.com/rastertiles/light_all/{z}/{x}/{y}.png';
const DARK_STYLE_ATTRIBUTION = '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>';

export default function LocationMap({ latitude, longitude, onMarkerClick, lastUpdate, currentCoordinates }: LocationMapProps) {
  useEffect(() => {
    // マーカーアイコンの設定
    L.Icon.Default.mergeOptions({
      iconUrl: markerIcon,
      iconRetinaUrl: markerIcon2x,
      shadowUrl: markerShadow,
    });
  }, []);

  if (!latitude || !longitude) {
    return (
      <Box sx={{
        width: '100%',
        height: '300px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        bgcolor: `${APP_CONFIG.THEME.SURFACE_COLOR}80`,
        borderRadius: 2
      }}>
        <Typography color={APP_CONFIG.THEME.TEXT_SECONDARY}>
          位置情報が利用できません
        </Typography>
      </Box>
    );
  }

  const position: [number, number] = [latitude, longitude];

  return (
    <Box>
      <Box sx={{ 
        height: '300px', 
        width: '100%',
        '& .leaflet-container': {
          height: '100%',
          width: '100%',
          borderRadius: '8px',
          backgroundColor: APP_CONFIG.THEME.SURFACE_COLOR
        }
      }}>
        <MapContainer
          center={position}
          zoom={15}
          scrollWheelZoom={false}
        >
          <TileLayer
            attribution={DARK_STYLE_ATTRIBUTION}
            url={DARK_STYLE_URL}
          />
          <Marker 
            position={position}
            eventHandlers={{
              click: () => {
                if (onMarkerClick) {
                  onMarkerClick();
                }
              }
            }}
          />
        </MapContainer>
      </Box>
      <Box sx={{ mt: 2 }}>
        {lastUpdate && currentCoordinates.latitude === null && (
          <Typography 
            variant="caption" 
            sx={{ 
              color: APP_CONFIG.THEME.TEXT_SECONDARY,
              display: 'block',
              textAlign: 'center',
              mb: 1
            }}
          >
            最終更新: {new Date(lastUpdate).toLocaleString('ja-JP')}
          </Typography>
        )}
        <Typography 
          variant="body2" 
          sx={{ 
            color: APP_CONFIG.THEME.TEXT_SECONDARY,
            textAlign: 'center'
          }}
        >
          {currentCoordinates.latitude === null ? (
            `最新の位置情報: ${latitude?.toFixed(6)} / ${longitude?.toFixed(6)}`
          ) : (
            `緯度: ${latitude?.toFixed(6)} / 経度: ${longitude?.toFixed(6)}`
          )}
        </Typography>
      </Box>
    </Box>
  );
} 