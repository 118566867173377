import { useState, useEffect, useCallback, useMemo, forwardRef, useImperativeHandle } from 'react';
import { S3Client, ListObjectsV2Command, GetObjectCommand } from '@aws-sdk/client-s3';
import {
  Box,
  Typography,
  CircularProgress,
  Alert,
  Button,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import ja from 'date-fns/locale/ja';
import { startOfDay, endOfDay } from 'date-fns';
import { APP_CONFIG } from '../../config/constants';
import { commonSelectStyles, commonMenuItemStyles, filterContainerStyles } from '../../styles/commonStyles';
import NoDataMessage from '../common/NoDataMessage';

interface VideoFile {
  key: string;
  url: string;
  lastModified: Date;
  size: string;
}

// 対応している動画形式を定義
const SUPPORTED_VIDEO_FORMATS = ['.mp4', '.webm', '.ogg'];

interface VideoTabProps {
  deviceId: string;
}

const VideoTab = forwardRef<{ handleRefresh: () => Promise<void> }, VideoTabProps>(({ deviceId }, ref) => {
  useImperativeHandle(ref, () => ({
    handleRefresh: async () => {
      await handleRefresh();
    }
  }));

  const [videos, setVideos] = useState<VideoFile[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [selectedVideo, setSelectedVideo] = useState<string>('');
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [availableDates, setAvailableDates] = useState<string[]>([]);

  const s3Client = useMemo(() => new S3Client({
    region: APP_CONFIG.AWS_CONFIG.REGION,
    credentials: {
      accessKeyId: APP_CONFIG.AWS_CONFIG.ACCESS_KEY_ID!,
      secretAccessKey: APP_CONFIG.AWS_CONFIG.SECRET_ACCESS_KEY!,
    },
  }), []);

  const formatFileSize = (bytes: number): string => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  const fetchVideos = useCallback(async (date: Date) => {
    if (!deviceId) return;
    setLoading(true);
    setError('');
    try {
      const dayStart = startOfDay(date);
      const dayEnd = endOfDay(date);
      
      const listCommand = new ListObjectsV2Command({
        Bucket: APP_CONFIG.S3_CONFIG.BUCKET_NAME,
        Prefix: `${APP_CONFIG.S3_CONFIG.BASE_PATH}/${deviceId}/${APP_CONFIG.PATHS.VIDEO}/`,
      });
      
      const result = await s3Client.send(listCommand);

      if (!result.Contents || result.Contents.length === 0) {
        console.log('No items found');
        setVideos([]);
        return;
      }

      const filteredContents = result.Contents.filter(item => {
        const itemDate = new Date(item.LastModified || Date.now());
        return itemDate >= dayStart && itemDate <= dayEnd;
      });

      if (filteredContents.length === 0) {
        console.log(`No items found for date: ${date.toLocaleDateString('ja-JP')}`);
        setVideos([]);
        return;
      }

      const videoFiles = await Promise.all(
        filteredContents.map(async (item) => {
          if (!item.Key) return null;
          
          // ファイルの拡張子をチェック
          const fileExtension = item.Key.toLowerCase().substring(item.Key.lastIndexOf('.'));
          if (!SUPPORTED_VIDEO_FORMATS.includes(fileExtension)) {
            console.log(`Unsupported video format: ${fileExtension}`);
            return null;
          }

          try {
            const getCommand = new GetObjectCommand({
              Bucket: APP_CONFIG.S3_CONFIG.BUCKET_NAME,
              Key: item.Key,
            });
            await s3Client.send(getCommand);
            const url = `https://${APP_CONFIG.S3_CONFIG.BUCKET_NAME}.s3.amazonaws.com/${item.Key}`;

            return {
              key: item.Key.split('/').pop() || item.Key,
              url: url,
              lastModified: new Date(item.LastModified || Date.now()),
              size: formatFileSize(item.Size || 0),
            };
          } catch (err) {
            console.error(`Error getting URL for ${item.Key}:`, err);
            return null;
          }
        })
      );

      const validFiles = videoFiles
        .filter((file): file is VideoFile => file !== null)
        .sort((a, b) => b.lastModified.getTime() - a.lastModified.getTime());

      console.log('Valid files found:', validFiles.length);
      setVideos(validFiles);
      
      setSelectedVideo('');

    } catch (err) {
      console.error('Error fetching videos:', err);
      setError(`動画データの取得に失敗しました: ${(err as any).message}`);
    } finally {
      setLoading(false);
    }
  }, [s3Client, deviceId]);

  const fetchAvailableDates = useCallback(async () => {
    if (!deviceId) return [];
    try {
      const listCommand = new ListObjectsV2Command({
        Bucket: APP_CONFIG.S3_CONFIG.BUCKET_NAME,
        Prefix: `${APP_CONFIG.S3_CONFIG.BASE_PATH}/${deviceId}/${APP_CONFIG.PATHS.VIDEO}/`,
      });
      
      const result = await s3Client.send(listCommand);
      if (!result.Contents) return [];

      const dates = result.Contents
        .filter(item => {
          if (!item.Key || !item.LastModified) return false;
          const fileExtension = item.Key.toLowerCase().substring(item.Key.lastIndexOf('.'));
          return SUPPORTED_VIDEO_FORMATS.includes(fileExtension);
        })
        .map(item => item.LastModified!.toISOString().split('T')[0])
        .filter((date, index, self) => self.indexOf(date) === index)
        .sort((a, b) => b.localeCompare(a));

      setAvailableDates(dates);
      return dates;
    } catch (error) {
      console.error('利用可能な日付の取得に失敗:', error);
      return [];
    }
  }, [deviceId, s3Client]);

  const handleRefresh = useCallback(async () => {
    if (!deviceId) return;
    setLoading(true);
    setError('');
    try {
      const dates = await fetchAvailableDates();
      
      if (dates.length > 0) {
        setSelectedDate(new Date());
        await fetchVideos(new Date());
      } else {
        setVideos([]);
        setSelectedVideo('');
      }
    } catch (err) {
      console.error('データの更新に失敗しました:', err);
      setError(`データの更新に失敗しました: ${(err as any).message}`);
    } finally {
      setLoading(false);
    }
  }, [deviceId, fetchAvailableDates, fetchVideos]);

  useImperativeHandle(ref, () => ({
    handleRefresh
  }), [handleRefresh]);

  // 初回マウント時のデータ取得
  useEffect(() => {
    if (deviceId) {
      handleRefresh();
    }
  }, [deviceId, handleRefresh]);

  const getVideoTimeFromKey = (key: string): string => {
    const timeMatch = key.match(/(\d{4})(\d{2})(\d{2})T(\d{2})(\d{2})(\d{2})/);
    if (timeMatch) {
      const [, year, month, day, hour, minute, second] = timeMatch;
      const date = new Date(
        parseInt(year),
        parseInt(month) - 1,
        parseInt(day),
        parseInt(hour),
        parseInt(minute),
        parseInt(second)
      );
      return date.toLocaleString('ja-JP', {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      });
    }
    return key;
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Typography variant="h6">録画データ</Typography>
        <Button 
          variant="contained" 
          onClick={handleRefresh}
          disabled={loading}
        >
          更新
        </Button>
      </Box>

      <Box sx={filterContainerStyles}>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
          <DatePicker
            label="日付でフィルター"
            value={selectedDate}
            onChange={(date) => {
              if (date) {
                setSelectedDate(date);
                setSelectedVideo('');
                setLoading(true);
                fetchVideos(date).finally(() => setLoading(false));
              }
            }}
            shouldDisableDate={(date) => {
              const dateStr = date.toISOString().split('T')[0];
              return !availableDates.includes(dateStr);
            }}
            sx={commonSelectStyles}
            slotProps={{
              textField: {
                InputProps: {
                  readOnly: true
                }
              }
            }}
          />
        </LocalizationProvider>

        {videos.length > 0 && (
          <FormControl fullWidth>
            <InputLabel sx={{ color: 'white' }}>動画を選択</InputLabel>
            <Select
              value={selectedVideo}
              onChange={(e) => setSelectedVideo(e.target.value)}
              label="動画を選択"
              sx={commonSelectStyles}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 300
                  }
                }
              }}
            >
              {videos.map((video) => (
                <MenuItem
                  key={video.url}
                  value={video.url}
                  sx={commonMenuItemStyles}
                >
                  {`${getVideoTimeFromKey(video.key)} (${video.size})`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Box>

      {loading && (
        <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
          <CircularProgress />
        </Box>
      )}

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      {!loading && !error && videos.length === 0 && (
        <NoDataMessage />
      )}

      {videos.length > 0 && (
        <Box sx={{ width: '100%' }}>
          {selectedVideo && (
            <Box sx={{ 
              width: '100%', 
              maxWidth: '1920px', 
              margin: '0 auto',
              aspectRatio: '16/9',
              bgcolor: APP_CONFIG.THEME.SURFACE_COLOR,
              borderRadius: 1,
              overflow: 'hidden'
            }}>
              <video
                controls
                style={{ width: '100%', height: '100%' }}
                src={selectedVideo}
                onLoadedData={(e) => {
                  const video = e.currentTarget;
                  video.currentTime = 0;
                  video.pause();
                }}
              >
                お使いのブラウザは動画の再生に対応していません。
              </video>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
});

export default VideoTab;
