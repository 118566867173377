export const commonSelectStyles = {
  color: 'white',
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: 'white',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: 'white',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: 'white',
  },
  '.MuiSvgIcon-root': {
    color: 'white',
  },
  '& .MuiInputBase-input': {
    color: 'white',
  },
  '& .MuiInputLabel-root': {
    color: 'white',
  },
};

export const commonMenuItemStyles = {
  color: 'black',
  bgcolor: 'white',
  '&:hover': {
    bgcolor: 'lightgray',
  },
};

export const filterContainerStyles = {
  display: 'flex',
  gap: 2,
  mb: 2,
  flexDirection: { xs: 'column', sm: 'row' },
  alignItems: 'center',
  '& > *': {
    flex: 1,
    maxWidth: { xs: '100%', sm: '400px' }
  }
} as const; 