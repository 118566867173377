export const APP_CONFIG = {
  APP_NAME: process.env.REACT_APP_NAME || 'MizLinxApp',
  S3_CONFIG: {
    BUCKET_NAME: process.env.REACT_APP_S3_BUCKET || 'mizlinx-monitor-demo',
    BASE_PATH: process.env.REACT_APP_S3_BASE_PATH || 'data-lake'
  },
  AWS_CONFIG: {
    REGION: process.env.REACT_APP_AWS_REGION || 'ap-northeast-1',
    ACCESS_KEY_ID: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    SECRET_ACCESS_KEY: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
  },
  PATHS: {
    VIDEO: 'bin',
    JSON: 'json',
    LATEST_JSON: 'latest.json',
  },
  THEME: {
    PRIMARY_COLOR: '#1976d2',
    SECONDARY_COLOR: '#f50057',
    BACKGROUND_COLOR: '#1a1b26',
    SURFACE_COLOR: '#24283b',
    TEXT_PRIMARY: '#c0caf5',
    TEXT_SECONDARY: '#a9b1d6',
    GOOD: '#4caf5033',
    NORMAL: '#c0caf5',
    WARNING: '#ff980033',
    CRITICAL: '#f4433633',
  },
  DEVICE_METRICS: {
    BATTERY_VOLTAGE: {
      MIN: Number(process.env.REACT_APP_MIN_BATTERY_VOLTAGE) || 8000,
      MAX: Number(process.env.REACT_APP_MAX_BATTERY_VOLTAGE) || 14400,
    },
    SOLAR_VOLTAGE: {
      MIN: Number(process.env.REACT_APP_MIN_SOLAR_VOLTAGE) || 10000,
      MAX: Number(process.env.REACT_APP_MAX_SOLAR_VOLTAGE) || 21000,
    },
    TEMPERATURE: {
      MIN: Number(process.env.REACT_APP_MIN_TEMPERATURE) || -20,
      MAX: Number(process.env.REACT_APP_MAX_TEMPERATURE) || 60,
    },
    HUMIDITY: {
      MIN: Number(process.env.REACT_APP_MIN_HUMIDITY) || 0,
      MAX: Number(process.env.REACT_APP_MAX_HUMIDITY) || 100,
    },
  },
  TABS: {
    CONSOLE: {
      ENABLED: true,
      LABEL: 'コンソール',
      KEY: 'console'
    },
    VIDEO: {
      ENABLED: false,
      LABEL: '動画',
      KEY: 'video'
    },
    SENSOR: {
      ENABLED: false,
      LABEL: 'センサー',
      KEY: 'sensor'
    },
    DEVICE: {
      ENABLED: false,
      LABEL: 'デバイス',
      KEY: 'device'
    },
    HEATMAP: {
      ENABLED: false,
      LABEL: 'ヒートマップ',
      KEY: 'heatmap'
    }
  },
  API: {
    ENDPOINT: process.env.REACT_APP_API_ENDPOINT || 'https://nbu6xh3vc1.execute-api.ap-northeast-1.amazonaws.com/dev',
  },
  DYNAMODB: {
    TABLE_NAME: process.env.REACT_APP_DYNAMODB_TABLE,
  },
}; 