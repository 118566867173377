import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ThemeProvider, createTheme, ThemeOptions } from '@mui/material';
import Login from './components/auth/Login';
import Dashboard from './components/dashboard/Dashboard';
import PasswordReset from './components/auth/PasswordReset';
import PrivateRoute from './components/auth/PrivateRoute';
import { AuthProvider } from './contexts/AuthContext';
import { APP_CONFIG } from './config/constants';
import './config/amplify';
import { useState } from 'react';
import SplashScreen from './components/common/SplashScreen';

const themeOptions: ThemeOptions = {
  components: {},
  palette: {
    mode: 'light',
    primary: {
      main: APP_CONFIG.THEME.PRIMARY_COLOR,
    },
    secondary: {
      main: APP_CONFIG.THEME.SECONDARY_COLOR,
    }
  }
};

const theme = createTheme(themeOptions);

function App() {
  const [isLoading, setIsLoading] = useState(true);

  const handleLoadComplete = () => {
    setIsLoading(false);
  };

  return (
    <>
      {isLoading && <SplashScreen onLoadComplete={handleLoadComplete} />}
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/reset-password" element={<PasswordReset />} />
              <Route
                path="/"
                element={
                  <PrivateRoute>
                    <Dashboard />
                  </PrivateRoute>
                }
              />
            </Routes>
          </BrowserRouter>
        </ThemeProvider>
      </AuthProvider>
    </>
  );
}

export default App;