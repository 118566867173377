import { Box, Link, Typography } from '@mui/material';
import { APP_CONFIG } from '../../config/constants';

export default function Footer() {
  return (
    <Box
      component="footer"
      sx={{
        width: '100%',
        py: 2,
        px: 2,
        mt: 'auto',
        backgroundColor: APP_CONFIG.THEME.SURFACE_COLOR,
        borderTop: '1px solid',
        borderColor: `${APP_CONFIG.THEME.TEXT_SECONDARY}20`,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 1
      }}
    >
      <Link
        href="https://mizlinx.com/"
        target="_blank"
        rel="noopener noreferrer"
        sx={{
          display: 'block',
          '& img': {
            height: '24px',
            width: 'auto'
          },
          '&:hover': {
            opacity: 0.8
          }
        }}
      >
        <img src="/mizlinx-logo.png" alt="MizLinx" />
      </Link>
      <Typography 
        variant="body2" 
        sx={{ 
          color: APP_CONFIG.THEME.TEXT_SECONDARY,
          opacity: 0.8
        }}
      >
        {'Copyright © '}
        {new Date().getFullYear()}
        {' MizLinx. All rights reserved.'}
      </Typography>
    </Box>
  );
} 