import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { signUp, confirmSignUp } from '@aws-amplify/auth';
import {
  Container,
  Box,
  TextField,
  Button,
  Alert,
  Link,
  IconButton,
  InputAdornment,
  Stepper,
  Step,
  StepLabel,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { APP_CONFIG } from '../../config/constants';

const textFieldStyles = {
  '& .MuiOutlinedInput-root': {
    color: APP_CONFIG.THEME.TEXT_PRIMARY,
    '& fieldset': {
      borderColor: `${APP_CONFIG.THEME.TEXT_SECONDARY}50`,
    },
    '&:hover fieldset': {
      borderColor: APP_CONFIG.THEME.TEXT_PRIMARY,
    },
    '&.Mui-focused fieldset': {
      borderColor: APP_CONFIG.THEME.PRIMARY_COLOR,
    },
  },
  '& .MuiInputLabel-root': {
    color: APP_CONFIG.THEME.TEXT_SECONDARY,
    '&.Mui-focused': {
      color: APP_CONFIG.THEME.PRIMARY_COLOR,
    },
  },
  '& .MuiIconButton-root': {
    color: APP_CONFIG.THEME.TEXT_SECONDARY,
  },
};

const buttonStyles = {
  bgcolor: APP_CONFIG.THEME.PRIMARY_COLOR,
  color: APP_CONFIG.THEME.TEXT_PRIMARY,
  '&:hover': {
    bgcolor: `${APP_CONFIG.THEME.PRIMARY_COLOR}CC`,
  },
  mt: 3,
  mb: 2
};

export default function Login() {
  const [isSignUp, setIsSignUp] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmationCode, setConfirmationCode] = useState('');
  const [error, setError] = useState('');
  const [signUpStep, setSignUpStep] = useState(0);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { signIn } = useAuth();
  const navigate = useNavigate();

  const signUpSteps = ['アカウント情報の入力', '確認コードの入力'];

  const handleSignUp = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await signUp({
        username: email,
        password,
        options: {
          userAttributes: {
            email,
          },
        },
      });
      setSignUpStep(1);
      setError('');
    } catch (err: any) {
      console.error('Sign up error:', err);
      setError(
        err.message || 
        'アカウント登録に失敗しました。環境設定を確認してください。'
      );
    }
  };

  const handleConfirmSignUp = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await confirmSignUp({
        username: email,
        confirmationCode,
      });
      setIsSignUp(false);
      setError('');
      setSignUpStep(0);
    } catch (err: any) {
      setError(err.message || '確認コードの検証に失敗しました');
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setError('');
    try {
      if (!email || !password) {
        throw new Error('メールアドレスとパスワードを入力してください');
      }
      
      if (password.length < 8) {
        throw new Error('パスワードは8文字以上である必要があります');
      }

      await signIn(email, password);
      navigate('/');
    } catch (err: any) {
      setError(err.message || 'ログインに失敗しました');
      console.error(err);
    }
  };

  return (
    <Box sx={{ 
      minHeight: '100vh',
      bgcolor: APP_CONFIG.THEME.BACKGROUND_COLOR,
      display: 'flex',
      flexDirection: 'column'
    }}>
      <Container component="main" maxWidth="xs">
        <Box sx={{ 
          mt: 8, 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center',
          bgcolor: APP_CONFIG.THEME.SURFACE_COLOR,
          p: 4,
          borderRadius: 2,
          boxShadow: '0 4px 20px 0 rgba(0,0,0,0.12)'
        }}>
          <Box
            component="img"
            src="/mizlinx-logo.png"
            alt="MizLinx"
            sx={{
              width: '200px',
              mb: 4
            }}
          />

          {isSignUp && (
            <Stepper 
              activeStep={signUpStep} 
              sx={{ 
                width: '100%', 
                mb: 3,
                '& .MuiStepLabel-label': {
                  color: APP_CONFIG.THEME.TEXT_SECONDARY,
                  '&.Mui-active': {
                    color: APP_CONFIG.THEME.TEXT_PRIMARY,
                  },
                },
                '& .MuiStepIcon-root': {
                  color: APP_CONFIG.THEME.TEXT_SECONDARY,
                  '&.Mui-active': {
                    color: APP_CONFIG.THEME.PRIMARY_COLOR,
                  },
                  '&.Mui-completed': {
                    color: APP_CONFIG.THEME.PRIMARY_COLOR,
                  },
                },
              }}
            >
              {signUpSteps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          )}

          {error && <Alert severity="error" sx={{ width: '100%', mb: 2 }}>{error}</Alert>}

          {isSignUp ? (
            signUpStep === 0 ? (
              <Box component="form" onSubmit={handleSignUp} sx={{ width: '100%' }}>
                <TextField
                  sx={textFieldStyles}
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="メールアドレス"
                  name="email"
                  autoComplete="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                  sx={textFieldStyles}
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="パスワード"
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="パスワードの表示切り替え"
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  sx={textFieldStyles}
                  margin="normal"
                  required
                  fullWidth
                  name="confirmPassword"
                  label="パスワード（確認）"
                  type={showConfirmPassword ? 'text' : 'password'}
                  id="confirmPassword"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="パスワード（確認）の表示切り替え"
                          onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                          edge="end"
                        >
                          {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={buttonStyles}
                >
                  アカウント登録
                </Button>
              </Box>
            ) : (
              <Box component="form" onSubmit={handleConfirmSignUp} sx={{ width: '100%' }}>
                <TextField
                  sx={textFieldStyles}
                  margin="normal"
                  required
                  fullWidth
                  id="confirmationCode"
                  label="確認コード"
                  name="confirmationCode"
                  value={confirmationCode}
                  onChange={(e) => setConfirmationCode(e.target.value)}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={buttonStyles}
                >
                  確認
                </Button>
              </Box>
            )
          ) : (
            <Box component="form" onSubmit={handleSubmit} sx={{ width: '100%' }}>
              <TextField
                sx={textFieldStyles}
                margin="normal"
                required
                fullWidth
                id="email"
                label="メールアドレス"
                name="email"
                autoComplete="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                sx={textFieldStyles}
                margin="normal"
                required
                fullWidth
                name="password"
                label="パスワード"
                type={showPassword ? 'text' : 'password'}
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="パスワードの表示切り替え"
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={buttonStyles}
              >
                ログイン
              </Button>
            </Box>
          )}

          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
            <Link
              sx={{
                color: APP_CONFIG.THEME.PRIMARY_COLOR,
                textDecoration: 'none',
                '&:hover': {
                  textDecoration: 'underline',
                },
              }}
              component="button"
              variant="body2"
              onClick={() => {
                setIsSignUp(!isSignUp);
                setError('');
                setSignUpStep(0);
              }}
            >
              {isSignUp ? 'ログインページへ' : 'アカウント登録'}
            </Link>
            {!isSignUp && (
              <Link
                sx={{
                  color: APP_CONFIG.THEME.PRIMARY_COLOR,
                  textDecoration: 'none',
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                }}
                component="button"
                variant="body2"
                onClick={() => navigate('/reset-password')}
              >
                パスワードを忘れた場合
              </Link>
            )}
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
