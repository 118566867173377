import { Amplify } from 'aws-amplify';

if (!process.env.REACT_APP_AWS_REGION || 
    !process.env.REACT_APP_USER_POOL_ID || 
    !process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID ||
    !process.env.REACT_APP_S3_BUCKET ||
    !process.env.REACT_APP_IDENTITY_POOL_ID) {
  throw new Error('Required environment variables are not set');
}

const config = {
  Auth: {
    Cognito: {
      userPoolId: process.env.REACT_APP_USER_POOL_ID,
      userPoolClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
      identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
      region: process.env.REACT_APP_AWS_REGION
    }
  },
  Storage: {
    S3: {
      bucket: process.env.REACT_APP_S3_BUCKET,
      region: process.env.REACT_APP_AWS_REGION,
      defaultAccessLevel: 'private'
    }
  }
};

console.log('Amplify Configuration:', config);
Amplify.configure(config);
