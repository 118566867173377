import { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import {
  AppBar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
  Button,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tabs,
  Tab,
  Select,
  FormControl,
  InputLabel,
  SelectChangeEvent
} from '@mui/material';
import {
  Menu as MenuIcon,
  VideoLibrary,
  Sensors,
  DevicesOther,
  AccountCircle,
  Logout,
  Dashboard,
  Map,
  Refresh
} from '@mui/icons-material';
import { APP_CONFIG } from '../../config/constants';
import { useDeviceList } from '../../hooks/useDeviceList';

interface NavigationProps {
  currentTab: number;
  onTabChange: (newValue: number) => void;
  onRefreshAll: () => Promise<void>;
  deviceId: string;
  onDeviceChange: (deviceId: string) => Promise<void>;
  isLoading: boolean;
  enabledTabs: Array<{
    component: any;
    enabled: boolean;
    ref: any;
  }>;
}

export default function Navigation({ 
  currentTab, 
  onTabChange, 
  onRefreshAll,
  deviceId,
  onDeviceChange,
  isLoading,
  enabledTabs
}: NavigationProps) {
  const { signOut, user } = useAuth();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [mobileOpen, setMobileOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { deviceList, loading } = useDeviceList();

  useEffect(() => {
    if (deviceList.length > 0 && !deviceId) {
      onDeviceChange(deviceList[0]);
    }
  }, [deviceList, deviceId, onDeviceChange]);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLogout = async () => {
    try {
      await signOut();
    } catch (error) {
      console.error('ログアウトエラー:', error);
    }
    handleClose();
  };

  const handleDeviceChange = (event: SelectChangeEvent<string>) => {
    onDeviceChange(event.target.value);
  };

  const tabs = [
    { 
      label: APP_CONFIG.TABS.CONSOLE.LABEL, 
      icon: <Dashboard />,
      key: 'console',
      enabled: true
    },
  ];

  const drawer = (
    <Box sx={{ width: 250 }}>
      <List>
        {tabs
          .filter(tab => tab.enabled)
          .map((tab, index) => (
            <ListItemButton
              key={tab.label}
              onClick={() => {
                onTabChange(index);
                handleDrawerToggle();
              }}
              selected={currentTab === index}
              sx={{ cursor: 'pointer' }}
            >
              <ListItemIcon>{tab.icon}</ListItemIcon>
              <ListItemText primary={tab.label} />
            </ListItemButton>
          ))}
      </List>
    </Box>
  );

  return (
    <>
      <AppBar 
        position="static" 
        sx={{ 
          bgcolor: APP_CONFIG.THEME.SURFACE_COLOR,
          color: APP_CONFIG.THEME.TEXT_PRIMARY,
          borderBottom: `1px solid ${APP_CONFIG.THEME.TEXT_SECONDARY}30`
        }}
      >
        <Toolbar>
          {isMobile && (
            <IconButton
              color="inherit"
              aria-label="メニューを開く"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
          )}

          <Typography variant="h6" component="div" sx={{ flexGrow: 0, mr: 4 }}>
            MizLinxApp
          </Typography>

          {!isMobile && (
            <Tabs
              value={currentTab}
              onChange={(_, newValue) => onTabChange(newValue)}
              sx={{ 
                flexGrow: 1,
                '& .MuiTab-root': {
                  color: APP_CONFIG.THEME.TEXT_SECONDARY,
                  '&.Mui-selected': {
                    color: APP_CONFIG.THEME.TEXT_PRIMARY,
                  }
                },
                '& .MuiTabs-indicator': {
                  backgroundColor: APP_CONFIG.THEME.PRIMARY_COLOR,
                }
              }}
            >
              {tabs
                .filter((tab, index) => enabledTabs[index]?.enabled)
                .map((tab) => (
                  <Tab
                    key={tab.label}
                    label={tab.label}
                    icon={tab.icon}
                    iconPosition="start"
                  />
                ))}
            </Tabs>
          )}

          <FormControl 
            sx={{ 
              minWidth: 120, 
              mr: 2,
              '& .MuiOutlinedInput-root': {
                color: APP_CONFIG.THEME.TEXT_PRIMARY,
                '& fieldset': {
                  borderColor: `${APP_CONFIG.THEME.TEXT_SECONDARY}50`,
                },
                '&:hover fieldset': {
                  borderColor: APP_CONFIG.THEME.PRIMARY_COLOR,
                },
              },
              '& .MuiInputLabel-root': {
                color: APP_CONFIG.THEME.TEXT_SECONDARY,
              },
              '& .MuiSelect-icon': {
                color: APP_CONFIG.THEME.TEXT_SECONDARY,
              }
            }}
            size="small"
          >
            <InputLabel id="device-select-label">デバイス</InputLabel>
            <Select
              labelId="device-select-label"
              value={deviceId}
              label="デバイス"
              onChange={handleDeviceChange}
              disabled={loading}
            >
              {deviceList.map((device) => (
                <MenuItem key={device} value={device}>
                  {device}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Button
            startIcon={<Refresh />}
            onClick={() => {
              if (!loading) {
                onRefreshAll();
              }
            }}
            disabled={loading}
            sx={{ 
              color: APP_CONFIG.THEME.TEXT_PRIMARY,
              mr: 2,
              '&:hover': {
                color: APP_CONFIG.THEME.PRIMARY_COLOR,
              }
            }}
          >
            全て更新
          </Button>

          <Box sx={{ flexGrow: 0 }}>
            {isMobile ? (
              <IconButton
                size="large"
                aria-label="アカウントメニュー"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
                sx={{ 
                  color: APP_CONFIG.THEME.TEXT_PRIMARY,
                  '&:hover': {
                    color: APP_CONFIG.THEME.PRIMARY_COLOR,
                  }
                }}
              >
                <AccountCircle />
              </IconButton>
            ) : (
              <Button
                onClick={handleMenu}
                startIcon={<AccountCircle />}
                color="inherit"
              >
                {user?.attributes?.email || 'ゲスト'}
              </Button>
            )}
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleLogout}>
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                ログアウト
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>

      <Drawer
        variant="temporary"
        anchor="left"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: 'block', md: 'none' },
          '& .MuiDrawer-paper': { 
            boxSizing: 'border-box', 
            width: 250,
            bgcolor: APP_CONFIG.THEME.SURFACE_COLOR,
            color: APP_CONFIG.THEME.TEXT_PRIMARY,
            '& .MuiListItemButton-root': {
              '&:hover': {
                bgcolor: `${APP_CONFIG.THEME.PRIMARY_COLOR}20`,
              },
              '&.Mui-selected': {
                bgcolor: `${APP_CONFIG.THEME.PRIMARY_COLOR}40`,
                '&:hover': {
                  bgcolor: `${APP_CONFIG.THEME.PRIMARY_COLOR}50`,
                }
              }
            },
            '& .MuiListItemIcon-root': {
              color: APP_CONFIG.THEME.TEXT_SECONDARY,
            }
          }
        }}
      >
        {drawer}
      </Drawer>
    </>
  );
}
