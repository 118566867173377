import { Box, Typography, CircularProgress } from '@mui/material';
import { APP_CONFIG } from '../../config/constants';

interface CircularMeterProps {
  value: number | null;
  maxValue: number;
  unit: string;
  icon: React.ReactNode;
  status: 'critical' | 'warning' | 'normal' | 'good';
  size?: number;
}

export default function CircularMeter({ value, maxValue, unit, icon, status, size = 100 }: CircularMeterProps) {
  const percentage = value !== null ? (value / maxValue) * 100 : 0;

  return (
    <Box sx={{ position: 'relative', width: size, height: size }}>
      <CircularProgress
        variant="determinate"
        value={percentage}
        size={size}
        thickness={4}
        sx={{
          color: status === 'good' ? APP_CONFIG.THEME.GOOD :
                 status === 'normal' ? APP_CONFIG.THEME.NORMAL :
                 status === 'warning' ? APP_CONFIG.THEME.WARNING : APP_CONFIG.THEME.CRITICAL,
          transition: 'all 0.2s cubic-bezier(0.34, 1.56, 0.64, 1)',
          '& .MuiCircularProgress-circle': {
            strokeLinecap: 'round',
            transition: 'all 0.2s cubic-bezier(0.34, 1.56, 0.64, 1)',
          },
        }}
      />
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: APP_CONFIG.THEME.TEXT_PRIMARY,
        pointerEvents: 'none'
      }}>
        {icon}
      </Box>
      <Typography variant="body1" sx={{ position: 'absolute', bottom: -20, width: '100%', textAlign: 'center', color: APP_CONFIG.THEME.TEXT_PRIMARY, fontWeight: 'bold' }}>
        {value !== null ? `${value.toFixed(1)}${unit}` : '--'}
      </Typography>
    </Box>
  );
} 